import styled from '@emotion/styled'
import tw from 'twin.macro'

export const HeroWrapper = styled.section`
  ${tw`
    overflow-hidden
    relative
    w-full
  `}
  &.home {
    ${tw`
      overflow-visible
    `}
  }
`

export const HeroIntroStyle = styled.div`
  ${tw`
    md:w-1/2
    pt-16
    pb-8
    lg:py-32
  `}
  &.home {
    ${tw`
      md:w-full
      lg:w-1/2
    `}
  }
`

export const HeroIntroTitle = styled.div`
  ${tw`
    tracking-tight
    leading-tight
    md:leading-normal
    font-bold
    text-5xl
    lg:leading-none
  `}
  &.home > h1 {
    ${tw`
      text-5xl
      lg:text-6xl
      leading-tight
      lg:leading-none
    `}
  }
  strong {
    ${tw`
      text-purple-300
      font-bold
      block
    `}
  }
  h1 {
    ${tw`
     text-purple-900
    `}
  }
  &.home > h1 {
    background: linear-gradient(180deg, #358ce5 0%, #5330ac 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  :not(strong) {
    display: block;
  }
`

export const HeroDescription = styled.div`
  ${tw`
    mt-8
    text-lg
    text-purple-300
    sm:text-xl
    md:max-w-3xl
    max-w-full
    h-auto
    lg:pr-28
  `}
  &.home {
    strong {
      color: #3983e0;
    }
  }
  &.authentication {
    strong {
      ${tw`
        text-blue-400
      `}
    }
  }
  &.users-management {
    strong {
      ${tw`
        text-green-500
      `}
    }
  }
  &.ui-editor {
    strong {
      color: #7126f2;
    }
  }
`

export const HeroCallToActionsWrapper = styled.div`
  ${tw`
    mt-8
    flex
    flex-row
    justify-start
    space-x-4
  `}
`

export const HeroCallToAction = styled.div`
  ${tw`
    rounded-full 
    shadow-sm
  `}
  .white {
    ${tw`
      w-full
      flex
      items-center
      justify-center
      px-8
      py-3
      border
      border-transparent
      text-base
      leading-6
      font-medium
      rounded-full
      text-indigo-600
      bg-white
      hover:bg-indigo-100
      focus:outline-none
      focus:border-indigo-700
      focus:shadow-outline-indigo
      transition
      duration-150
      ease-in-out
      md:py-4
      md:text-lg
      md:px-10
    `}
  }
  .color {
    ${tw`
      w-full
      flex
      items-center
      justify-center
      px-8
      py-3
      border
      border-transparent
      text-base
      leading-6
      font-medium
      rounded-full
      text-white
      bg-indigo-600
      hover:bg-indigo-500
      focus:outline-none
      focus:border-indigo-700
      focus:shadow-outline-indigo
      transition
      duration-150
      ease-in-out
      md:py-4
      md:text-lg
      md:px-10
    `}
  }
`

export const HeroValuePropositionsGrid = styled.div`
  ${tw`
    pb-20
    md:pb-32
    md:flex
    md:justify-center
  `}
  ul {
    ${tw`
      md:w-full
      grid 
      grid-cols-2 
      gap-y-20
      md:grid-cols-4
    `}
  }
`

export const HeroHeader = styled.div`
  ${tw`
    flex
    w-full
    lg:flex-row
    lg:items-center
  `}
`

export const ProductCategory = styled.div`
  ${tw`
    flex
    flex-row
    items-center
    w-full
    my-4
  `}
  header {
    ${tw`
      mr-4
      flex
      items-center
    `}
    svg {
      ${tw`
        mr-4
      `}
    }
    h1 {
      ${tw`
        text-base
        font-bold
      `}
    }
    h1.authentication,
    h1.scim {
      ${tw`
        text-blue-400
      `}
    }
    h1.users-management {
      ${tw`
        text-green-500
      `}
    }
    h1.ui-editor,
    h1.sso-admin-onboarding {
      color: #7126f2;
    }
    h1.enterprise-connections {
      color: #5851ff;
    }
  }
`

export const HeroValueProposition = styled.div`
  ${tw`
    mt-8
    space-y-1
    md:space-y-0
    grid
    grid-cols-2
    grid-rows-2
    col-gap-8 
    row-gap-1
  `}
`

export const HeroValuePropositionItem = styled.div`
  ${tw`
    flex
    flex-row
    items-start
    lg:items-center
  `}
`

export const HeroKicker = styled.div`
  ${tw`
    mb-6
    text-base
    font-bold
    uppercase
  `}
  color: #4A3274;
`

export const HeroValuePropositionIcon = styled.div`
  ${tw`
    flex
    items-center
    justify-center
    h-4
    w-4
    mt-1
    lg:mt-0
    mr-2
    rounded-full
    bg-indigo-200
  `}
  svg {
    ${tw`
      h-2
      w-3
    `}
  }
`

export const HeroValuePropositionText = styled.div`
  p {
    ${tw`
      text-sm
      lg:text-base
      font-medium
      text-purple-900
    `}
  }
`
